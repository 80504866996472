import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState, } from 'react';
import { getCaretIndex, getSelection, insertNodeAtCaret, isFirefox, updateCaret, } from '../../../../utils/contentEditable';
import { RiEmotionLine, RiSendPlaneFill } from 'react-icons/ri';
import { classNames } from '../../../../helper/style';
import { isChatToggledState, useChatWidgetStore } from '../../../../store/actions';
const brRegex = /<br>/g;
function onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
}
function onPaste(event) {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
    document.execCommand('insertHTML', false, text);
}
function Sender({ sendMessage, placeholder, disabledInput, autofocus, onTextInputChange, onPressEmoji, onChangeSize, }, ref) {
    const showChat = useChatWidgetStore(isChatToggledState);
    const inputRef = useRef(null);
    const refContainer = useRef(null);
    const [enter, setEnter] = useState(false);
    const [firefox, setFirefox] = useState(false);
    const [height, setHeight] = useState(0);
    const [hasValue, setHasValue] = useState(false);
    useEffect(() => {
        if (showChat && autofocus)
            inputRef.current?.focus();
    }, [showChat]);
    useEffect(() => {
        setFirefox(isFirefox());
    }, []);
    useImperativeHandle(ref, () => ({
        onSelectEmoji: handlerOnSelectEmoji,
    }));
    const handlerOnChange = (event) => {
        if (!inputRef.current)
            return;
        setHasValue(!!inputRef.current.innerHTML);
        onTextInputChange?.(event);
    };
    const handlerSendMessage = () => {
        const el = inputRef.current;
        if (!el)
            return;
        if (el.innerHTML) {
            sendMessage(el.innerText);
            el.innerHTML = '';
            setHasValue(false);
        }
    };
    const handlerOnSelectEmoji = (emoji) => {
        const el = inputRef.current;
        if (!el)
            return;
        const selection = getSelection(el);
        if (!selection)
            return;
        const { start, end } = selection;
        if (el.innerHTML) {
            const firstPart = el.innerHTML.substring(0, start);
            const secondPart = el.innerHTML.substring(end);
            el.innerHTML = `${firstPart}${emoji.native}${secondPart}`;
        }
        else {
            el.innerHTML = emoji.native;
        }
        updateCaret(el, start, emoji.native.length);
        setHasValue(!!el.innerHTML);
    };
    const handlerOnKeyPress = (event) => {
        const el = inputRef.current;
        if (!el)
            return;
        if (event.charCode === 13 && !event.shiftKey) {
            event.preventDefault();
            handlerSendMessage();
        }
        if (event.charCode === 13 && event.shiftKey) {
            event.preventDefault();
            insertNodeAtCaret(el);
            setEnter(true);
        }
        setHasValue(!!el.innerHTML);
    };
    // TODO use a context for checkSize and toggle picker
    const checkSize = () => {
        const senderEl = refContainer.current;
        if (senderEl && height !== senderEl.clientHeight) {
            const { clientHeight } = senderEl;
            setHeight(clientHeight);
            onChangeSize(clientHeight ? clientHeight - 1 : 0);
        }
    };
    const handlerOnKeyUp = (event) => {
        const el = inputRef.current;
        if (!el)
            return true;
        // Conditions need for firefox
        if (firefox && event.key === 'Backspace') {
            if (el.innerHTML.length === 1 && enter) {
                el.innerHTML = '';
                setEnter(false);
            }
            else if (brRegex.test(el.innerHTML)) {
                el.innerHTML = el.innerHTML.replace(brRegex, '');
            }
        }
        checkSize();
    };
    const handlerOnKeyDown = (event) => {
        const el = inputRef.current;
        if (!el)
            return;
        if (event.key === 'Backspace') {
            const caretPosition = getCaretIndex(el);
            const character = el.innerHTML.charAt(caretPosition - 1);
            if (character === '\n') {
                event.preventDefault();
                event.stopPropagation();
                el.innerHTML =
                    el.innerHTML.substring(0, caretPosition - 1) + el.innerHTML.substring(caretPosition);
                updateCaret(el, caretPosition, -1);
            }
        }
        setHasValue(!!el.innerHTML);
    };
    const handlerPressEmoji = () => {
        onPressEmoji();
        checkSize();
    };
    return (_jsxs("div", { ref: refContainer, className: "pointer-events-auto relative flex items-end justify-end space-x-2 rounded-b-lg bg-gray-100 p-2", children: [_jsx("button", { type: "submit", disabled: disabledInput, onClick: handlerPressEmoji, children: _jsx(RiEmotionLine, { className: classNames('h-8 w-8 transition-all duration-200 ease-in-out', disabledInput
                        ? 'fill-gray-300'
                        : 'fill-primary-800 hover:fill-primary-400 focus:fill-primary-400') }) }), _jsx("div", { spellCheck: true, className: classNames('mx-2 h-16 w-full resize-none rounded-lg border p-2 text-left transition-all duration-200 ease-in-out', disabledInput ? 'bg-gray-100 opacity-50' : 'bg-white opacity-100'), role: "textbox", contentEditable: !disabledInput, onDrop: onDrop, onPaste: onPaste, ref: inputRef, placeholder: placeholder, onInput: handlerOnChange, onKeyPress: handlerOnKeyPress, onKeyUp: handlerOnKeyUp, onKeyDown: handlerOnKeyDown }), _jsx("button", { type: "submit", className: "rcw-send", disabled: disabledInput, onClick: handlerSendMessage, children: _jsx(RiSendPlaneFill, { className: classNames('z-20 h-8 w-8 transition-all duration-200 ease-in-out', disabledInput
                        ? 'cursor-default fill-gray-300'
                        : hasValue
                            ? 'fill-primary-800 hover:fill-primary-400 focus:fill-primary-400 cursor-pointer'
                            : 'cursor-default fill-gray-300 hover:fill-gray-300 focus:fill-gray-300') }) })] }));
}
export default forwardRef(Sender);
