import { useReducer, useState } from 'react';
const initState = {
    layout: { width: 768 },
    zoom: false,
    direction: 'vertical',
};
const usePreview = (zoomStep) => {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const [fileSize, setFileSize] = useState({ width: 0, height: 0 });
    const reducer = (state, action) => {
        switch (action.type) {
            case 'initLayout':
                return {
                    ...state,
                    layout: action.payload.layout,
                    direction: action.payload.direction,
                    zoom: false,
                };
            case 'zoomIn':
                return {
                    ...state,
                    layout: action.layout,
                    zoom: true,
                };
            case 'zoomOut':
                return {
                    ...state,
                    layout: action.layout,
                    zoom: true,
                };
            case 'resetZoom':
                return {
                    ...state,
                    layout: action.layout,
                    direction: action.direction ?? state.direction,
                };
            default:
                throw new Error('Unexpected action');
        }
    };
    const [state, dispatch] = useReducer(reducer, { ...initState });
    const initFileSize = (width, height) => {
        const { innerWidth, innerHeight } = window;
        setWindowSize({ width: innerWidth, height: innerHeight });
        // default size
        setFileSize({ width, height });
        const payload = { layout: {}, direction: 'horizontal' };
        /**
         * Calculate the display ratio of screen to picture
         */
        if (innerWidth / innerHeight <= width / height) {
            payload.layout.width = innerWidth * 0.8;
            payload.direction = 'horizontal';
        }
        else {
            payload.layout.height = innerHeight * 0.8;
            payload.direction = 'vertical';
        }
        dispatch({
            type: 'initLayout',
            payload,
        });
    };
    const getLayout = (step) => {
        let layout;
        if (state.direction === 'vertical') {
            layout = {
                height: (state.layout.height ?? 0) + step,
            };
        }
        else {
            layout = {
                width: (state.layout.width ?? 0) + step,
            };
        }
        return layout;
    };
    const isMinSize = () => {
        if (state.direction === 'vertical') {
            return (state.layout.height ?? 0) > windowSize.height / 3;
        }
        return (state.layout.width ?? 0) > windowSize.width / 3;
    };
    const onZoomIn = () => {
        dispatch({
            type: 'zoomIn',
            layout: getLayout(zoomStep ?? 0),
        });
    };
    const onZoomOut = () => {
        if (isMinSize()) {
            dispatch({
                type: 'zoomOut',
                layout: getLayout(-(zoomStep ?? 0)),
            });
        }
    };
    const onResizePageZoom = () => {
        if (state.zoom) {
            initFileSize(fileSize.width, fileSize.height);
        }
    };
    return {
        state,
        initFileSize,
        onZoomIn,
        onZoomOut,
        onResizePageZoom,
    };
};
export default usePreview;
