import { jsx as _jsx } from "react/jsx-runtime";
import Widget from './components/molecules';
import './index.css';
export function ConnectedWidget({ title, titleAvatar, subtitle, senderPlaceHolder, showCloseButton, fullScreenMode, autofocus, profileAvatar, profileClientAvatar, launcher, handleNewUserMessage, handleQuickButtonClicked, handleTextInputChange, chatId, handleToggle, launcherOpenLabel, launcherCloseLabel, launcherCloseImg, launcherOpenImg, sendButtonAlt, showTimeStamp, imagePreview, zoomStep, handleSubmit, showBadge, resizable, defaultShowChat, }) {
    return (_jsx(Widget, { title: title, titleAvatar: titleAvatar, subtitle: subtitle, handleNewUserMessage: handleNewUserMessage, handleQuickButtonClicked: handleQuickButtonClicked, senderPlaceHolder: senderPlaceHolder, profileAvatar: profileAvatar, profileClientAvatar: profileClientAvatar, showCloseButton: showCloseButton, fullScreenMode: fullScreenMode, autofocus: autofocus, customLauncher: launcher, handleTextInputChange: handleTextInputChange, chatId: chatId, handleToggle: handleToggle, launcherOpenLabel: launcherOpenLabel, launcherCloseLabel: launcherCloseLabel, launcherCloseImg: launcherCloseImg, launcherOpenImg: launcherOpenImg, sendButtonAlt: sendButtonAlt, showTimeStamp: showTimeStamp, imagePreview: imagePreview, zoomStep: zoomStep, handleSubmit: handleSubmit, showBadge: showBadge, resizable: resizable, defaultShowChat: defaultShowChat }));
}
const defaultProps = {
    title: 'Welcome',
    subtitle: 'This is your chat subtitle',
    senderPlaceHolder: 'Type a message...',
    showCloseButton: true,
    fullScreenMode: false,
    autofocus: true,
    chatId: 'rcw-chat-container',
    launcherOpenLabel: 'Open chat',
    launcherCloseLabel: 'Close chat',
    launcherOpenImg: '',
    launcherCloseImg: '',
    sendButtonAlt: 'Send',
    showTimeStamp: true,
    imagePreview: false,
    zoomStep: 80,
    showBadge: true,
};
ConnectedWidget.defaultProps = defaultProps;
