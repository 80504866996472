import { conversationCommandTypeFromJSON, ConversationMessageAdditionalDataKey, } from '@auxilabs/protomono';
import { ConversationMessageAdditionalDataType } from '../interface/conversation';
export function mapToProtoConversationMessage(domain) {
    return {
        additionalData: domain.additionalData
            ? Object.entries(domain.additionalData).map(([key, value]) => {
                const additionalDataType = ConversationMessageAdditionalDataType[key];
                return {
                    key: ConversationMessageAdditionalDataKey[additionalDataType],
                    value,
                };
            })
            : [],
        message: domain.message,
        conversationCommand: conversationCommandTypeFromJSON(`CONVERSATION_COMMAND_TYPE_${domain.conversationCommand}`),
        timestamp: new Date(),
    };
}
