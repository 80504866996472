import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import QuickButtonComponent from './quickButton';
import { quickButtonsState, useChatWidgetStore } from '../../../../store/actions';
function QuickButtons({ onQuickButtonClicked }) {
    const quickButtons = useChatWidgetStore(quickButtonsState);
    if (!quickButtons.length)
        return null;
    return onQuickButtonClicked ? (_jsx("div", { className: "pointer-events-auto flex justify-center bg-white p-2", children: _jsx("ul", { className: "flex h-full w-full flex-1 flex-wrap items-center justify-center", children: quickButtons.map((button, index) => (_jsx("li", { className: "inline-block m-1", children: _jsx(QuickButtonComponent, { onQuickButtonClicked: onQuickButtonClicked, button: button }) }, `${button.text}-${index}`))) }) })) : (_jsx(_Fragment, {}));
}
export default QuickButtons;
