import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import NimblePicker from 'emoji-mart/dist-es/components/picker/nimble-picker';
import cn from 'classnames';
import 'emoji-mart/css/emoji-mart.css';
import Header from './header';
import Messages from './messages';
import Sender from './sender';
import QuickButtons from './quickButtons';
function Conversation({ title, subtitle, senderPlaceHolder, showCloseButton, disabledInput, autofocus, showChat, sendMessage, toggleChat, profileAvatar, profileClientAvatar, titleAvatar, onQuickButtonClicked, onTextInputChange, showTimeStamp, resizable, }) {
    const [data, setData] = useState(null);
    useEffect(() => {
        fetch('https://cdn.jsdelivr.net/npm/emoji-mart@3.0.1/data/all.json').then((data) => {
            data.json().then((e) => setData(e));
        });
    }, []);
    const [pickerOffset, setOffset] = useState(0);
    const senderRef = useRef(null);
    const [pickerStatus, setPicket] = useState(false);
    const onSelectEmoji = (emoji) => {
        senderRef.current?.onSelectEmoji(emoji);
    };
    const togglePicker = () => {
        setPicket((prevPickerStatus) => !prevPickerStatus);
    };
    const handlerSendMsn = (event) => {
        sendMessage(event);
        if (pickerStatus)
            setPicket(false);
    };
    return (_jsxs("div", { 
        // id="rcw-conversation-container"
        className: cn('transform-all pointer-events-auto flex h-full flex-grow origin-[95%_100%] flex-col rounded-lg shadow-lg transition-all duration-300 ease-in-out', showChat ? 'skew-y-0 scale-100 opacity-100' : 'skew-y-6 scale-0 opacity-0'), "aria-live": "polite", children: [resizable && (_jsx("div", { className: "cursor-col-resize\th-full left-0 top-0 absolute w-1 pointer-events-auto" })), _jsx(Header, { title: title, subtitle: subtitle, toggleChat: toggleChat, showCloseButton: showCloseButton, titleAvatar: titleAvatar }), _jsx(Messages, { profileAvatar: profileAvatar, profileClientAvatar: profileClientAvatar, showTimeStamp: showTimeStamp, isBlur: pickerStatus, toggleBlur: setPicket }), _jsx(QuickButtons, { onQuickButtonClicked: onQuickButtonClicked }), pickerStatus && !disabledInput && (_jsx(_Fragment, { children: data ? (_jsx(NimblePicker, { showPreview: false, showSkinTones: false, style: {
                        position: 'absolute',
                        bottom: pickerOffset,
                        left: '0',
                        width: '100%',
                        zIndex: 20,
                    }, onSelect: onSelectEmoji, data: data })) : (_jsx(_Fragment, {})) })), _jsx(Sender, { ref: senderRef, sendMessage: handlerSendMsn, placeholder: senderPlaceHolder, disabledInput: disabledInput, autofocus: autofocus, onTextInputChange: onTextInputChange, onPressEmoji: togglePicker, onChangeSize: setOffset })] }));
}
export default Conversation;
