import io from 'socket.io-client';
import { useChatWidgetStore } from './store/actions';
import { useEffect, useRef, useState } from 'react';
import { SocketConversationMessage, SocketConversationResponse } from '@auxilabs/protomono';
import { ConversationCommandType, mapToDomain, mapToProtoConversationMessage, } from '@auxilabs/model-conversation-component';
import { MessageSender } from './constants';
export function useSocket(data) {
    const [socketHandler, setSocketHandler] = useState(null);
    const currentData = useRef(null);
    const dropMessage = useChatWidgetStore((state) => state.dropMessages);
    const renderCustomComponent = useChatWidgetStore((state) => state.renderCustomComponent);
    const addUserMessage = useChatWidgetStore((state) => state.addUserMessage);
    const setQuickButtons = useChatWidgetStore((state) => state.setQuickButtons);
    const toggleMsgLoader = useChatWidgetStore((state) => state.toggleMsgLoader);
    useEffect(() => {
        dropMessage();
    }, []);
    useEffect(() => {
        if (!data) {
            return;
        }
        // Required because strict mode is funky.
        // https://upmostly.com/tutorials/why-is-my-useeffect-hook-running-twice-in-react
        if (currentData.current === data) {
            return;
        }
        currentData.current = data;
        const socket = io(`${process.env.REACT_APP_WEBSOCKET_ENDPOINT}/chat?clientId=${data.clientId}&applicationId=${data.applicationId}`, {
            transports: ['websocket', 'polling'],
            auth: {
                username: 'anil',
            },
        });
        socket.on('connect', function () {
            // dispatch(
            //   actions.addResponseMessage({
            //     text: 'Hello! You are chatting with Auxi!',
            //   }),
            // )
            // console.log('Established connection to server.')
        });
        socket.on('chat', function (response) {
            const result = SocketConversationResponse.decode(new Uint8Array(response));
            const componentWrapper = {
                elements: result.components.map(mapToDomain),
                sender: MessageSender.RESPONSE,
                timestamp: new Date(),
            };
            toggleMsgLoader(false);
            renderCustomComponent(componentWrapper, false);
        });
        socket.on('disconnect', function () {
            // console.log('Chat is dced')
        });
        socket.on('reconnect_attempt', (attempts) => {
            // console.log(`Try to reconnect at ${attempts} attempt(s).`)
        });
        socket.on('connect_error', (err) => {
            // console.log(`connect_error due to ${err.message}`)
        });
        const sendMessage = (message) => {
            const data = SocketConversationMessage.encode(mapToProtoConversationMessage(message)).finish();
            socket.emit('chat', data.buffer.slice(data.byteOffset, data.byteLength + data.byteOffset));
            toggleMsgLoader(true);
        };
        const handleNewUserMessage = (newMessage) => {
            sendMessage({
                message: newMessage,
                actionTime: new Date().toISOString(),
                conversationCommand: ConversationCommandType.ROUTINE,
            });
        };
        const handleQuickButtonClick = (quickButton) => {
            const chatMessage = {
                message: quickButton.text,
                conversationCommand: quickButton.conversationCommand,
                actionTime: new Date().toISOString(),
                additionalData: quickButton.additionalData,
            };
            sendMessage(chatMessage);
            addUserMessage(quickButton.text);
            setQuickButtons([]);
        };
        setSocketHandler({ handleNewUserMessage, handleQuickButtonClick });
        return () => {
            socket.disconnect();
        };
    }, [data]);
    return socketHandler;
}
