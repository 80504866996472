export var MessageSender;
(function (MessageSender) {
    MessageSender["CLIENT"] = "client";
    MessageSender["RESPONSE"] = "response";
})(MessageSender || (MessageSender = {}));
export var MessageType;
(function (MessageType) {
    MessageType["TEXT"] = "text";
    MessageType["SNIPPET_LINK"] = "snippet";
    MessageType["CUSTOM_COMPONENT"] = "component";
})(MessageType || (MessageType = {}));
export const MESSAGE_BOX_SCROLL_DURATION = 400;
