export var ConversationCommandType;
(function (ConversationCommandType) {
    ConversationCommandType["LAUNCH"] = "LAUNCH";
    ConversationCommandType["QUIT"] = "QUIT";
    ConversationCommandType["RESUME"] = "RESUME";
    ConversationCommandType["ROUTINE"] = "ROUTINE";
    ConversationCommandType["SWITCH"] = "SWITCH";
})(ConversationCommandType || (ConversationCommandType = {}));
export var ConversationMessageAdditionalDataType;
(function (ConversationMessageAdditionalDataType) {
    ConversationMessageAdditionalDataType["NEW_INTENT"] = "NEW_INTENT";
})(ConversationMessageAdditionalDataType || (ConversationMessageAdditionalDataType = {}));
