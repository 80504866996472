import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
function Badge({ badge }) {
    const [existingBadge, setExistingBadge] = useState(badge);
    useEffect(() => {
        if (badge !== 0) {
            setExistingBadge(badge);
        }
    }, [badge]);
    return (_jsx("span", { className: "min-w-5 inline-flex h-5 items-center justify-center rounded-full border-2 border-red-500 bg-red-600", children: _jsx("span", { className: "p-1 text-sm font-medium leading-none text-white", children: existingBadge }) }));
}
export default Badge;
