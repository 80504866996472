import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import usePreview from './usePreview';
import usePortal from './usePortal';
import { fullScreenState, useChatWidgetStore } from '../../../store/actions';
import { HiPlus, HiX } from 'react-icons/hi';
import { HiArrowsPointingIn, HiArrowsPointingOut, HiMinus } from 'react-icons/hi2';
export default function FullScreenPreview({ zoomStep }) {
    const { state, initFileSize, onZoomIn, onZoomOut, onResizePageZoom } = usePreview(zoomStep);
    const fullScreen = useChatWidgetStore(fullScreenState);
    const closeFullscreenPreview = useChatWidgetStore((state) => state.closeFullscreenPreview);
    // const { src, alt, width, height, visible } = useSelector(
    //   (state: GlobalState) => ({
    //     src: state.preview.src,
    //     alt: state.preview.alt,
    //     width: state.preview.width,
    //     height: state.preview.height,
    //     visible: state.preview.visible,
    //   })
    // );
    useEffect(() => {
        if (fullScreen?.src) {
            initFileSize(fullScreen?.width, fullScreen?.height);
        }
    }, [fullScreen?.src]);
    const pDom = usePortal();
    const onClosePreview = () => {
        closeFullscreenPreview();
    };
    const childNode = (_jsxs("div", { className: "w-screen h-screen overflow-hidden fixed z-50 left-0 top-0 pointer-events-auto", children: [_jsx("div", { className: "w-full h-full overflow-scroll relative", children: _jsx("img", { ...state.layout, src: fullScreen?.src, className: "absolute inset-0 m-auto", alt: fullScreen?.alt }) }), _jsx("button", { className: "p-0 m-1 border flex items-center justify-center outline-none bg-white border-none absolute inset-0", onClick: onClosePreview, children: _jsx(HiX, {}) }), _jsxs("div", { className: "fixed flex flex-col justify-center items-center", children: [_jsx("button", { className: "p-0 m-1 border flex items-center justify-center outline-none bg-white border-none absolute", onClick: onResizePageZoom, children: state.zoom ? _jsx(HiArrowsPointingOut, {}) : _jsx(HiArrowsPointingIn, {}) }), _jsx("button", { className: "p-0 m-1 border flex items-center justify-center outline-none bg-white border-none absolute", onClick: onZoomIn, children: _jsx(HiPlus, {}) }), _jsx("button", { className: "p-0 m-1 border flex items-center justify-center outline-none bg-white border-none absolute", onClick: onZoomOut, children: _jsx(HiMinus, {}) })] })] }));
    return fullScreen !== null ? ReactDOM.createPortal(childNode, pDom) : null;
}
