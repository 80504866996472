export var ConversationComponentRootType;
(function (ConversationComponentRootType) {
    ConversationComponentRootType["BUTTONS_V1"] = "buttons.v1";
    ConversationComponentRootType["DROPDOWN_V1"] = "dropdown.v1";
    ConversationComponentRootType["IMAGE_V1"] = "image.v1";
    ConversationComponentRootType["LINK_V1"] = "link.v1";
    ConversationComponentRootType["MARKDOWN_V1"] = "markdown.v1";
    ConversationComponentRootType["TEXT_V1"] = "text.v1";
    ConversationComponentRootType["NULL_V1"] = "null.v1";
})(ConversationComponentRootType || (ConversationComponentRootType = {}));
export var ComponentAdditionalDataType;
(function (ComponentAdditionalDataType) {
    ComponentAdditionalDataType["NEW_INTENT"] = "NEW_INTENT";
    ComponentAdditionalDataType["PROCEED_MESSAGE"] = "PROCEED_MESSAGE";
})(ComponentAdditionalDataType || (ComponentAdditionalDataType = {}));
