import { jsx as _jsx } from "react/jsx-runtime";
import { isChatToggledState, useChatWidgetStore } from '../../store/actions';
import WidgetLayout from './layout';
import { useEffect } from 'react';
function Widget({ title, titleAvatar, subtitle, senderPlaceHolder, profileAvatar, profileClientAvatar, showCloseButton, fullScreenMode, autofocus, customLauncher, handleNewUserMessage, handleQuickButtonClicked, handleTextInputChange, chatId, handleToggle, launcherOpenLabel, launcherCloseLabel, launcherCloseImg, launcherOpenImg, sendButtonAlt, showTimeStamp, imagePreview, zoomStep, handleSubmit, showBadge, resizable, defaultShowChat, }) {
    const isWidgetOpened = useChatWidgetStore(isChatToggledState);
    const reset = useChatWidgetStore((state) => state.reset);
    const toggleChat = useChatWidgetStore((state) => state.toggleChat);
    const addUserMessage = useChatWidgetStore((state) => state.addUserMessage);
    useEffect(() => {
        reset();
    }, []);
    const toggleConversation = () => {
        toggleChat();
        handleToggle?.(isWidgetOpened);
    };
    const handleMessageSubmit = (userInput) => {
        if (!userInput.trim()) {
            return;
        }
        handleSubmit?.(userInput);
        addUserMessage(userInput);
        handleNewUserMessage(userInput);
    };
    const onQuickButtonClicked = (event, quickButton) => {
        event.preventDefault();
        handleQuickButtonClicked?.(quickButton);
    };
    return (_jsx(WidgetLayout, { onToggleConversation: toggleConversation, onSendMessage: handleMessageSubmit, onQuickButtonClicked: onQuickButtonClicked, title: title, titleAvatar: titleAvatar, subtitle: subtitle, senderPlaceHolder: senderPlaceHolder, profileAvatar: profileAvatar, profileClientAvatar: profileClientAvatar, showCloseButton: showCloseButton, fullScreenMode: fullScreenMode, autofocus: autofocus, customLauncher: customLauncher, onTextInputChange: handleTextInputChange, chatId: chatId, launcherOpenLabel: launcherOpenLabel, launcherCloseLabel: launcherCloseLabel, launcherCloseImg: launcherCloseImg, launcherOpenImg: launcherOpenImg, sendButtonAlt: sendButtonAlt, showTimeStamp: showTimeStamp, imagePreview: imagePreview, zoomStep: zoomStep, showBadge: showBadge, resizable: resizable, defaultShowChat: defaultShowChat }));
}
export default Widget;
