import { ComponentAdditionalDataType, ConversationComponentRootType, } from '../interface/component';
import { ComponentButtonAdditionalDataKey, componentButtonAdditionalDataKeyFromJSON, ConversationCommandType as ConversationCommandTypeProto, conversationCommandTypeFromJSON, } from '@auxilabs/protomono';
import { ConversationCommandType } from '@auxilabs/model-conversation-component';
export function mapToProto(domain) {
    switch (domain.type) {
        case ConversationComponentRootType.BUTTONS_V1:
            return {
                component: {
                    $case: 'buttonGroup',
                    buttonGroup: {
                        items: domain.value.buttons.map(({ text, additionalData, conversationCommand }) => ({
                            text,
                            additionalData: Object.entries(additionalData).map(([key, value]) => {
                                return {
                                    key: componentButtonAdditionalDataKeyFromJSON(`COMPONENT_BUTTON_ADDITIONAL_DATA_KEY_${key}`),
                                    value,
                                };
                            }),
                            conversationCommand: conversationCommandTypeFromJSON(`CONVERSATION_COMMAND_TYPE_${conversationCommand}`),
                        })),
                        isBlocking: domain.value.blocking,
                        slotId: domain.value.slotId,
                    },
                },
            };
        case ConversationComponentRootType.DROPDOWN_V1:
            return {
                component: {
                    $case: 'dropdownGroup',
                    dropdownGroup: {
                        items: domain.value.items.map(({ text }) => ({ text })),
                        isBlocking: true,
                        slotId: undefined,
                    },
                },
            };
        case ConversationComponentRootType.IMAGE_V1:
            return {
                component: {
                    $case: 'image',
                    image: domain.value,
                },
            };
        case ConversationComponentRootType.LINK_V1:
            return {
                component: {
                    $case: 'link',
                    link: domain.value,
                },
            };
        case ConversationComponentRootType.TEXT_V1:
            return {
                component: {
                    $case: 'text',
                    text: domain.value,
                },
            };
        case ConversationComponentRootType.MARKDOWN_V1:
            return {
                component: {
                    $case: 'markdown',
                    markdown: domain.value,
                },
            };
        case ConversationComponentRootType.NULL_V1:
            return null;
    }
}
export function mapToDomain(domain) {
    const component = domain.component;
    if (!component) {
        return {
            type: ConversationComponentRootType.NULL_V1,
            value: null,
        };
    }
    switch (component.$case) {
        case 'buttonGroup':
            return {
                type: ConversationComponentRootType.BUTTONS_V1,
                value: {
                    buttons: component.buttonGroup.items.map((button) => {
                        const commandTypeProtoElement = ConversationCommandTypeProto[button.conversationCommand];
                        const conversationCommand = commandTypeProtoElement === 'UNSPECIFIED'
                            ? ConversationCommandType.ROUTINE
                            : ConversationCommandType[commandTypeProtoElement];
                        return {
                            text: button.text,
                            additionalData: button.additionalData.reduce((acc, val) => {
                                const result = ComponentButtonAdditionalDataKey[val.key];
                                const keyEnum = ComponentAdditionalDataType[result];
                                return keyEnum ? { ...acc, [keyEnum]: val.value } : acc;
                            }, {}),
                            conversationCommand: conversationCommand,
                        };
                    }),
                    blocking: component.buttonGroup.isBlocking,
                    slotId: component.buttonGroup.slotId,
                },
            };
        case 'dropdownGroup':
            return {
                type: ConversationComponentRootType.DROPDOWN_V1,
                value: {
                    items: component.dropdownGroup.items.map((dropdown) => ({
                        text: dropdown.text,
                        action: dropdown.text,
                    })),
                },
            };
        case 'image':
            return {
                type: ConversationComponentRootType.IMAGE_V1,
                value: component.image,
            };
        case 'link':
            return {
                type: ConversationComponentRootType.LINK_V1,
                value: component.link,
            };
        case 'markdown':
            return {
                type: ConversationComponentRootType.MARKDOWN_V1,
                value: component.markdown,
            };
        case 'text':
            return {
                type: ConversationComponentRootType.TEXT_V1,
                value: component.text,
            };
    }
}
