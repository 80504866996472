import { jsx as _jsx } from "react/jsx-runtime";
import DOMPurify from 'dompurify';
const defaultOptions = {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a'],
    ALLOWED_ATTR: ['href'],
};
const sanitize = (dirty, options) => ({
    __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
});
const SanitizeHtml = ({ html, options }) => (_jsx("div", { dangerouslySetInnerHTML: sanitize(html, options) }));
export default SanitizeHtml;
