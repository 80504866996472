import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { isChatToggledState, isFullscreenEnabledState, isInputEnabledState, useChatWidgetStore, } from '../../store/actions';
import Conversation from './conversation';
import Launcher from './launcher';
import FullScreenPreview from './fullScreenPreview';
function WidgetLayout({ title, titleAvatar, subtitle, onSendMessage, onToggleConversation, senderPlaceHolder, onQuickButtonClicked, profileAvatar, profileClientAvatar, showCloseButton, fullScreenMode, autofocus, customLauncher, onTextInputChange, chatId, launcherOpenLabel, launcherCloseLabel, launcherCloseImg, launcherOpenImg, sendButtonAlt, showTimeStamp, imagePreview, zoomStep, showBadge, resizable, defaultShowChat, }) {
    const isChatToggled = useChatWidgetStore(isChatToggledState);
    const showChat = defaultShowChat ?? isChatToggled;
    const isInputEnabled = useChatWidgetStore(isInputEnabledState);
    const visible = useChatWidgetStore(isFullscreenEnabledState);
    const openFullscreenPreview = useChatWidgetStore((state) => state.openFullscreenPreview);
    const messageRef = useRef(null);
    useEffect(() => {
        if (showChat) {
            messageRef.current = document.getElementById('messages');
        }
        return () => {
            messageRef.current = null;
        };
    }, [showChat]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventHandle = (evt) => {
        if (evt.target && evt.target.className === 'rcw-message-img') {
            const { src, alt, naturalWidth, naturalHeight } = evt.target;
            const obj = {
                src: src,
                alt: alt,
                width: naturalWidth,
                height: naturalHeight,
            };
            openFullscreenPreview(obj);
        }
    };
    /**
     * Previewer needs to prevent body scroll behavior when fullScreenMode is true
     */
    useEffect(() => {
        const target = messageRef?.current;
        if (imagePreview && showChat) {
            target?.addEventListener('click', eventHandle, false);
        }
        return () => {
            target?.removeEventListener('click', eventHandle);
        };
    }, [imagePreview, showChat]);
    useEffect(() => {
        document.body.setAttribute('style', `overflow: ${visible || fullScreenMode ? 'hidden' : 'auto'}`);
    }, [fullScreenMode, visible]);
    return (_jsxs("div", { className: "justify-right align-end pointer-events-none absolute bottom-0 right-0 flex h-full w-full flex-col space-y-4 p-4", children: [_jsx(Conversation, { title: title, subtitle: subtitle, sendMessage: onSendMessage, senderPlaceHolder: senderPlaceHolder, profileAvatar: profileAvatar, profileClientAvatar: profileClientAvatar, toggleChat: onToggleConversation, showCloseButton: showCloseButton, disabledInput: !isInputEnabled, autofocus: autofocus, titleAvatar: titleAvatar, showChat: showChat, onQuickButtonClicked: onQuickButtonClicked, onTextInputChange: onTextInputChange, sendButtonAlt: sendButtonAlt, showTimeStamp: showTimeStamp, resizable: resizable }), customLauncher
                ? customLauncher(onToggleConversation)
                : !fullScreenMode && (_jsx(Launcher, { toggle: onToggleConversation, chatId: chatId, openLabel: launcherOpenLabel, closeLabel: launcherCloseLabel, closeImg: launcherCloseImg, openImg: launcherOpenImg, showBadge: showBadge })), imagePreview && _jsx(FullScreenPreview, { fullScreenMode: fullScreenMode, zoomStep: zoomStep })] }));
}
export default WidgetLayout;
