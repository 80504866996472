import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { ConversationComponentRootType, } from '@auxilabs/model-conversation-component';
import Markdown from './markdown';
import { MessageSender } from '../../../constants';
import { classNames } from '../../../helper/style';
import format from 'date-fns/format';
import { useChatWidgetStore } from '../../../store/actions';
const ConversationComponentElement = (wrapper) => {
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col w-full flex-wrap items-start justify-start space-y-2", children: [wrapper.elements.map((component) => (_jsx(ConversationComponentMapping, { component: component, sender: wrapper.sender }))), _jsx("span", { className: "items-begin mr-auto text-[10px]", children: format(new Date(wrapper.timestamp), 'hh:mm') })] }) }));
};
function ButtonV1Component({ component }) {
    const setQuickButtons = useChatWidgetStore((state) => state.setQuickButtons);
    useEffect(() => {
        setQuickButtons(component.buttons);
    }, [component.buttons]);
    return _jsx(_Fragment, {});
}
function DropdownV1Component({ component }) {
    return (_jsx("div", { className: "focus:border-primary-500 focus:ring-primary-500 w-full rounded-md border border-gray-300 bg-white focus:outline-none focus:ring-1", children: _jsx("select", { className: "w-full cursor-default overflow-hidden border-r-8 border-transparent bg-transparent py-2 pl-3 text-left shadow-sm", children: component.items.map(({ text, action }) => (_jsx("option", { value: action, children: text }))) }) }));
}
function LinkV1Component({ component }) {
    return (_jsx("a", { className: "text-primary-600 focus-within:ring-primary-500 hover:text-primary-400 relative cursor-pointer break-words rounded-md text-left font-medium underline focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2", href: component.url, target: "_blank", rel: "noreferrer", children: component.text || component.url }));
}
function ImageV1Component({ component }) {
    return (_jsx("img", { className: "max-w-120 max-h-120 h-full w-full rounded-md shadow-md", src: component.url, alt: component.alt }));
}
function MarkdownV1Component({ component }) {
    return _jsx(Markdown, { html: component.text, options: null });
}
function TextV1Component({ component, sender }) {
    return (_jsx(NonInteractiveComponent, { sender: sender, children: component.text?.replaceAll('<br>', ' ') }));
}
function NonInteractiveComponent({ children, sender }) {
    return (_jsx("div", { className: classNames('py-3 px-4 text-white', sender === MessageSender.CLIENT
            ? 'rounded-bl-3xl rounded-tl-3xl rounded-tr-xl bg-gray-400'
            : 'bg-primary-600 rounded-br-3xl rounded-tr-3xl rounded-tl-xl'), children: children }));
}
function ConversationComponentMapping({ component, sender, }) {
    switch (component.type) {
        case ConversationComponentRootType.BUTTONS_V1:
            return _jsx(ButtonV1Component, { component: component.value });
        case ConversationComponentRootType.DROPDOWN_V1:
            return _jsx(DropdownV1Component, { component: component.value });
        case ConversationComponentRootType.LINK_V1:
            return _jsx(LinkV1Component, { component: component.value });
        case ConversationComponentRootType.IMAGE_V1:
            return _jsx(ImageV1Component, { component: component.value });
        case ConversationComponentRootType.MARKDOWN_V1:
            return _jsx(MarkdownV1Component, { component: component.value });
        case ConversationComponentRootType.TEXT_V1:
            return _jsx(TextV1Component, { component: component.value, sender: sender });
    }
    return _jsx(_Fragment, {});
}
export default ConversationComponentElement;
