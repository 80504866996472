import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Badge from './launcherBadge';
import { badgeCountState, isChatToggledState, useChatWidgetStore } from '../../../store/actions';
import { RiChatSmile2Line, RiCloseCircleLine } from 'react-icons/ri';
import { classNames } from '../../../helper/style';
function Launcher({ toggle, chatId, showBadge }) {
    const showChat = useChatWidgetStore(isChatToggledState);
    const badgeCount = useChatWidgetStore(badgeCountState);
    const setBadgeCount = useChatWidgetStore((state) => state.setBadgeCount);
    const toggleChat = () => {
        toggle();
        if (!showChat)
            setBadgeCount(0);
    };
    return (_jsx("div", { className: "pointer-events-auto flex self-end", children: _jsxs("button", { type: "button", className: "bg-primary-600 block h-16 w-16 rounded-full text-white shadow-lg", onClick: toggleChat, "aria-controls": chatId, children: [_jsx("span", { className: classNames('transform-all absolute left-0 top-0 pt-4 transition-all duration-200 ease-in-out', badgeCount > 0 && !showChat && showBadge ? 'mt-0 opacity-100' : '-mt-2 opacity-0'), children: _jsx(Badge, { badge: badgeCount }) }), _jsxs("div", { className: "relative flex h-full w-full items-center justify-center", children: [_jsx(RiChatSmile2Line, { className: classNames('transform-all absolute h-8 w-8 transition-all duration-200 ease-in-out', showChat ? 'rotate-180 opacity-0' : 'rotate-0 opacity-100') }), _jsx(RiCloseCircleLine, { className: classNames('transform-all absolute h-8 w-8 transition-all duration-200 ease-in-out', showChat ? 'rotate-180 opacity-100' : 'rotate-0 opacity-0') })] })] }) }));
}
export default Launcher;
