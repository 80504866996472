import { MESSAGE_BOX_SCROLL_DURATION, MessageSender, MessageType } from '../constants';
export function createNewMessage(text, sender, id) {
    return {
        type: MessageType.TEXT,
        text,
        sender,
        timestamp: new Date().toString(),
        showAvatar: true,
        customId: id,
        unread: sender === MessageSender.RESPONSE,
    };
}
export function createLinkSnippet(link, id) {
    return {
        type: MessageType.SNIPPET_LINK,
        title: link.title,
        link: link.link,
        target: link.target || '_blank',
        sender: MessageSender.RESPONSE,
        timestamp: new Date().toString(),
        showAvatar: true,
        customId: id,
        unread: true,
    };
}
export function createComponentMessage(props, showAvatar, id) {
    return {
        type: MessageType.CUSTOM_COMPONENT,
        props,
        sender: MessageSender.RESPONSE,
        timestamp: new Date().toString(),
        showAvatar,
        customId: id,
        unread: true,
    };
}
// TODO: Clean functions and window use for SSR
function sinEaseOut(timestamp, beginning, change, duration) {
    return change * ((timestamp = timestamp / duration - 1) * timestamp * timestamp + 1) + beginning;
}
/**
 *
 * @param {*} target scroll target
 * @param {*} scrollStart
 * @param {*} scroll scroll distance
 */
function scrollWithSlowMotion(target, scrollStart, scroll) {
    const raf = window?.requestAnimationFrame;
    let start = 0;
    const step = (timestamp) => {
        if (!start) {
            start = timestamp;
        }
        const stepScroll = sinEaseOut(timestamp - start, 0, scroll, MESSAGE_BOX_SCROLL_DURATION);
        const total = scrollStart + stepScroll;
        target.scrollTop = total;
        if (total < scrollStart + scroll) {
            raf(step);
        }
    };
    raf(step);
}
export function scrollToBottom(messagesDiv) {
    if (!messagesDiv)
        return;
    const screenHeight = messagesDiv.clientHeight;
    const scrollTop = messagesDiv.scrollTop;
    const scrollOffset = messagesDiv.scrollHeight - (scrollTop + screenHeight);
    if (scrollOffset)
        scrollWithSlowMotion(messagesDiv, scrollTop, scrollOffset);
}
