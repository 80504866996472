import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import format from 'date-fns/format';
import markdownIt from 'markdown-it';
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import markdownItSup from 'markdown-it-sup';
// @ts-ignore
import markdownItSanitizer from 'markdown-it-sanitizer';
/* eslint-enable */
import markdownItLinkAttributes from 'markdown-it-link-attributes';
import { MessageSender } from '../../../../../constants';
import { useEffect, useState } from 'react';
import cn from 'classnames';
function Message({ message }) {
    const sanitizedHTML = markdownIt({ breaks: true })
        // .use(markdownItClass, {
        //   img: ["rcw-message-img"],
        // })
        .use(markdownItSup)
        .use(markdownItSanitizer)
        .use(markdownItLinkAttributes, {
        attrs: { target: '_blank', rel: 'noopener' },
    })
        .render(message.text);
    const [attached, setAttached] = useState(false);
    useEffect(() => {
        setAttached(true);
    }, []);
    const isClient = message.sender === MessageSender.CLIENT;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: cn('relative mt-4 flex transition-all transform-all ease-in-out duration-500', attached ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1', isClient ? 'justify-end' : 'justify-start'), children: _jsx("div", { className: cn('py-3 px-4 text-white', isClient
                        ? 'rounded-bl-3xl rounded-tl-3xl rounded-tr-xl bg-gray-400'
                        : 'bg-primary-600 rounded-br-3xl rounded-tr-3xl rounded-tl-xl'), dangerouslySetInnerHTML: { __html: sanitizedHTML.replace(/\n$/, '') } }) }), _jsx("span", { className: `mx-2 text-[10px] ${isClient ? 'ml-auto' : 'mr-auto'}`, children: format(new Date(message.timestamp), 'hh:mm') })] }));
}
export default Message;
