import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import format from 'date-fns/format';
import { scrollToBottom } from '../../../../utils/messages';
import { badgeCountState, chatMessageState, isChatToggledState, quickButtonsState, typingState, useChatWidgetStore, } from '../../../../store/actions';
import { MessageSender, MessageType } from '../../../../constants';
import Loader from './loader';
import { classNames } from '../../../../helper/style';
import Snippet from './snippet';
import Message from './message';
import ConversationComponentElement from '../../../atoms/conversationComponentElement';
const getComponentToRender = (message, showTimeStamp) => {
    switch (message.type) {
        case MessageType.TEXT:
            return _jsx(Message, { message: message, showTimeStamp: showTimeStamp });
        case MessageType.SNIPPET_LINK:
            return _jsx(Snippet, { message: message, showTimeStamp: showTimeStamp });
        case MessageType.CUSTOM_COMPONENT:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return _jsx(ConversationComponentElement, { ...message.props, timestamp: message.timestamp });
    }
};
function Messages({ showTimeStamp, isBlur, toggleBlur }) {
    const messages = useChatWidgetStore(chatMessageState);
    const unreadMessages = messages.filter(({ unread }) => unread).length;
    const badgeCount = useChatWidgetStore(badgeCountState);
    const showChat = useChatWidgetStore(isChatToggledState);
    const typing = useChatWidgetStore(typingState);
    const quickButtons = useChatWidgetStore(quickButtonsState);
    const markAllMessagesRead = useChatWidgetStore((state) => state.markAllMessagesRead);
    const setBadgeCount = useChatWidgetStore((state) => state.setBadgeCount);
    const messageRef = useRef(null);
    useEffect(() => {
        if (showChat && unreadMessages !== 0) {
            markAllMessagesRead();
        }
        else if (badgeCount === unreadMessages) {
            setBadgeCount(unreadMessages);
        }
    }, [unreadMessages, badgeCount, showChat]);
    useEffect(() => {
        scrollToBottom(messageRef.current);
    }, [messages, quickButtons]);
    // TODO: Fix this function or change to move the avatar to last message from response
    // const shouldRenderAvatar = (message: Message, index: number) => {
    //   const previousMessage = messages[index - 1];
    //   if (message.showAvatar && previousMessage.showAvatar) {
    //     dispatch(hideAvatar(index));
    //   }
    // }
    return (_jsx("div", { className: "relative h-auto max-h-full flex-grow overflow-y-scroll", ref: messageRef, children: _jsxs("div", { onClick: () => isBlur && toggleBlur(false), className: classNames('space-y-4 bg-white py-2 px-2', isBlur ? 'pointer-events-auto blur-sm' : 'pointer-events-none blur-none'), children: [messages?.map((message, index) => {
                    const isMessageFromClient = message.sender === MessageSender.CLIENT;
                    return (_jsx("div", { className: classNames('flex flex-col', isMessageFromClient ? 'justify-end' : 'justify-start'), children: getComponentToRender(message, showTimeStamp) }, `${index}-${format(new Date(message.timestamp), 'hh:mm')}`));
                }), _jsx(Loader, { typing: typing })] }) }));
}
export default Messages;
