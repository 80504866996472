import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import './index.css';
import './App.css';
import { ConnectedWidget as Widget, useSocket } from '@auxilabs/ui-chat-widget';
import 'react-chat-widget/lib/styles.css';
function App() {
    const params = useMemo(() => window.location.search
        .substring(1)
        .split('&')
        .filter((e) => !!e)
        .reduce((acc, val) => {
        const [key, value] = val.split('=');
        return { ...acc, [key]: value };
    }, {}), [window.location.search]);
    const applicationId = params['applicationId'];
    const clientId = 'f678dfef-536d-418c-ac7c-4b2f51125a96';
    const socketData = useMemo(() => {
        return applicationId && clientId ? { applicationId, clientId } : null;
    }, [applicationId, clientId]);
    const socket = useSocket(socketData);
    return (_jsx("div", { className: "App relative min-h-screen", children: socket ? (_jsx(Widget, { title: "Auxi", handleNewUserMessage: socket.handleNewUserMessage, handleQuickButtonClicked: socket.handleQuickButtonClick, subtitle: "" })) : (_jsx(_Fragment, {})) }));
}
export default App;
