import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import cn from 'classnames';
import { useEffect, useState } from 'react';
const dotLength = 3;
function Dots() {
    const dots = [];
    for (let i = 0; i < dotLength; i++) {
        dots.push(_jsx("span", { className: "inline-block h-2 w-2 rounded-full bg-white animate-bounce", style: {
                animation: `bounce 1s ${i / dotLength}s infinite`,
            } }));
    }
    return _jsx(_Fragment, { children: dots });
}
function Loader({ typing }) {
    const [attached, setAttached] = useState(false);
    useEffect(() => {
        setAttached(!typing);
    }, [typing]);
    return typing ? (_jsx("div", { className: cn('flex transition ease-in-out duration-500 delay-200', attached ? 'opacity-0 translate-y-1' : 'opacity-100 translate-y-0'), children: _jsx("div", { className: "py-3 px-4 text-white bg-primary-600 rounded-br-3xl rounded-tr-3xl rounded-tl-xl space-x-2", children: _jsx(Dots, {}) }) })) : (_jsx(_Fragment, {}));
}
export default Loader;
