import { create } from 'zustand';
import { createComponentMessage, createLinkSnippet, createNewMessage } from '../../utils/messages';
import { immer } from 'zustand/middleware/immer';
import { MessageSender } from '../../constants';
const initialState = {
    isChatToggled: false,
    isInputEnabled: true,
    isTyping: false,
    chat: [],
    quickButtons: [],
    badgeCount: 0,
    fullScreen: null,
};
export const useChatWidgetStore = create()(immer((set) => ({
    ...initialState,
    reset: () => set(() => initialState),
    toggleChat: () => set((state) => {
        state.isChatToggled = !state.isChatToggled;
    }),
    toggleInputDisabled: () => set((state) => {
        state.isInputEnabled = !state.isInputEnabled;
    }),
    enableUserInput: () => set((state) => {
        state.isInputEnabled = true;
    }),
    addUserMessage: (text, id) => set((state) => {
        state.chat = [...state.chat].concat(createNewMessage(text, MessageSender.CLIENT, id));
    }),
    addResponseMessage: (text, id) => set((state) => {
        state.chat = [...state.chat].concat(createNewMessage(text, MessageSender.RESPONSE, id));
    }),
    toggleMsgLoader: (isTyping) => set((state) => {
        state.isTyping = isTyping;
    }),
    addLinkSnippet: (link, id) => set((state) => {
        state.chat = [...state.chat].concat(createLinkSnippet(link, id));
    }),
    renderCustomComponent: (props, showAvatar, id) => set((state) => {
        state.chat = [...state.chat].concat(createComponentMessage(props, showAvatar, id));
    }),
    dropMessages: () => set((state) => {
        state.chat = [];
    }),
    hideAvatar: (selectedIndex) => set((state) => {
        state.chat = state.chat.map((chat, index) => ({
            ...chat,
            showAvatar: index === selectedIndex ? false : chat.showAvatar,
        }));
    }),
    setQuickButtons: (buttons) => set((state) => {
        state.quickButtons = buttons;
        state.isInputEnabled = !buttons.length;
    }),
    deleteMessages: (count, id) => set((state) => {
        state.chat = id
            ? state.chat.filter((_, index) => {
                const targetMsg = state.chat.findIndex((tMsg) => tMsg.customId === id);
                return index < targetMsg - count + 1 || index > targetMsg;
            })
            : state.chat.slice(0, state.chat.length - count);
    }),
    setBadgeCount: (badgeCount) => set((state) => {
        state.badgeCount = badgeCount;
    }),
    markAllMessagesRead: () => set((state) => {
        state.chat = state.chat.map((message) => ({ ...message, unread: false }));
        state.badgeCount = 0;
    }),
    openFullscreenPreview: (fullScreen) => set((state) => {
        state.fullScreen = fullScreen;
    }),
    closeFullscreenPreview: () => set((state) => {
        state.fullScreen = null;
    }),
})));
export const isFullscreenEnabledState = (state) => state.fullScreen !== null;
export const isInputEnabledState = (state) => state.isInputEnabled ?? initialState.isInputEnabled;
export const isChatToggledState = (state) => state.isChatToggled ?? initialState.isChatToggled;
export const chatMessageState = (state) => state.chat;
export const badgeCountState = (state) => state.badgeCount;
export const typingState = (state) => state.isTyping;
export const quickButtonsState = (state) => state.quickButtons;
export const fullScreenState = (state) => state.fullScreen;
